import React from "react"
import styled from 'styled-components'
// import logo from '../images/companyLogo.png'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { details, P, SectionWrap, md, Logo } from '../common'

const logo = "https://technologytherapy.com/wp-content/themes/ttg/dist/images/logo.png"

const PP = styled(P)`
  min-width:600px;
  box-shadow: 3px 7px 10px #888888;

  ${md} {
    width: 150px;
  }
`

export default () => {
  return(
  <Layout>
    <SEO title="Thank You!!!" />
    <SectionWrap>
      <div css={`max-width:600px;margin:auto;padding:1rem;min-height:100vh;${md}{padding:0;}`}>
      <br /><br /><br /><br /><br />
      <Logo src={logo} alt="company logo" />
      <br /><br />
      <h2>Success!</h2>
      <p>Thank you for your time. We will be in touch shortly.</p>
      <br />
      <a href={details.site}><PP>Visit Us Online</PP></a>
      <br />
      </div>
    </SectionWrap>
  </Layout>
)}
